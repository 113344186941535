<template>
    <div>
        <img src="@/image/xq(2).gif" class="img">
        <!-- <div class="box">{{info.symbol}} <span style="color: #FF0000;">#{{ids}}</span></div> -->
        <!-- 价格 -->
        <div class="title">MTDAO MTG</div>
        <div class="box_a">
            <div class="flex" style="height: 30px; line-height: 30px;">
                <div>{{$t('nftDetails.sold')}}：</div>
                <div v-if="ids>=0">{{ids}}</div>
            </div>
            <div class="flex" v-if="sell_num>0" style="height: 30px; line-height: 30px;">
                <div>{{$t('nftDetails.community_sold')}}：</div>
                <div>{{sell_num}}</div>
            </div>
            <div class="flex" style="height: 30px; line-height: 30px;">
                <div>{{$t('nftDetails.total_supply')}}：</div>
                <!-- <div>{{info.balance}}</div> -->
                <div>100</div>
            </div>
        </div>
        <!-- 介绍 -->
        <div style="color: #FFFFFF; padding: 10px 10px 70px 10px;border-top: 10px solid #001020;">
            <div style="font-size:calc(var(--text) - 1px);">{{$t('nftDetails.introduce')}}</div>
            <div style="font-size:calc(var(--text) - 2px);margin-top:5px;">{{$t('nftDetails.introduce_txt')}}</div>
        </div>
        <!-- 切换 -->
        <!-- <div class="ntf_box">
            <div class="flex_pj" style="border-bottom: 1px solid #F4F4F4;font-size:calc(var(--text) - 3px)">
                <div class="hand" @click="ntf_switch = 1" :class="ntf_switch==1?'ntf_sw':'ntf_row'">{{$t('nftDetails.brief')}}</div>
                <div class="hand" @click="ntf_switch = 2" :class="ntf_switch==2?'ntf_sw':'ntf_row'">{{$t('nftDetails.rule')}}</div>
                <div class="hand" @click="ntf_switch = 3" :class="ntf_switch==3?'ntf_sw':'ntf_row'">{{$t('nftDetails.rights')}}</div>
            </div>
            <div v-if="ntf_switch == 1" style="padding: 10px;font-size:calc(var(--text) - 3px)">
                <img src="@/image/shouye_23.png" class="img">
                <div style="margin-top: 10px;">{{$t('nftDetails.ntf_txt')}}：</div>
                <div style="margin-top: 10px;">{{$t('nftDetails.ntf_txt_a')}}</div>
                <img src="@/image/shouye_24.png" style="margin-top: 10px;" class="img">
                <div style="margin-top: 10px;">{{$t('nftDetails.ntf_txt_b')}}：</div>
                <div style="margin-top: 10px;">{{$t('nftDetails.ntf_txt_c')}}</div>
            </div>
            <div v-if="ntf_switch == 2" style="padding: 10px;font-size:calc(var(--text) - 3px)">
                <img src="@/image/shouye_25.png" class="img">
                <div style="margin: 10px 0;">{{$t('nftDetails.rule_title_a')}}</div>
                <div v-for="(item,index) in $t('nftDetails.rule_arr')" :key="index">{{item}}</div>
            </div>
            <div v-if="ntf_switch == 3" style="padding: 10px;font-size:calc(var(--text) - 3px)">
            <div style="margin-top: 10px;" v-html="$t('nftDetails.rights_title_a')"></div>
                <div style="margin-top: 10px;" v-html="$t('nftDetails.rights_txt_a')"></div>
            </div>
        </div> -->
        <!-- 底部購買 -->
        <div style="height: 50px;"></div>
        <div class="bot_box" :style="'width:'+$setWidth">
            <div class="flex_lr" style="position: relative;width: 100%;">
                <div class="back flex_center" @click="bk"><i class="ifont icone-fanhui"></i></div>
                <div class="bot_center flex_row">{{$t('nftDetails.mtg_price')}}：<span>${{info.rate}}</span></div>
                <div class="purchase flex_center hand" @click="openBuy">{{$t('nftDetails.purchase')}}</div>
            </div>
        </div>
        <!-- 購買彈窗 -->
        <div v-if="is_mtg">
            <div class="flex_center mask_purchase">
                <div class="mask" style="z-index:99;" @click="mtg()"></div>
                <div class="flex_ud purchase_box" :class="tx == true?'open':'close'" :style="$setWidth=='100%'?'width: 85%;':'width: 400px;'">
                    <div class="flex_lr" style="font-size: 20px;margin:2px 0;">
                        <div></div>
                        <div>{{info.symbol}}</div>
                        <div class="hand" @click="mtg()">×</div>
                    </div>
                    <div class="flex_lr" style="font-size: 18px;margin:20px 0;">
                        <div>{{$t('nft.mtg_price')}}</div>
                        <div style="color: #E4AF1E;">{{info.rate}}USDT</div>
                    </div>
                    <div class="flex_lr" style="font-size: 18px;">
                        <!-- <div style="margin: 10px;">{{$t('nftDetails.approve')}}</div> -->
                        <div class="flex_row">
                            <div style="color: #e0e0e0;">{{approve_num}}</div>
                            <!-- <i class="ifont icone-icon_tianjia add" @click="is_num=!is_num"/> -->
                        </div>
                    </div>
                    <!-- <div style="color: #999999;">{{$t('nftDetails.mask_purchase')}}</div> -->
                    <div class="flex_lr" style="font-size: 16px;margin:20px 0;">
                        <div class="flex_row">
                            <img src="@/image/s_10.png" style="width: 22px; height: 22px; margin: 0 10px;">
                            <div style="font-size: 13px;">{{$t('nftDetails.payment')}}</div>
                        </div>
                        <div class="flex_row" style="color: #E4AF1E;">
                            <div style="font-size: 13px;">{{parseFloat(balance).toFixed(6)}} USDT</div>
                            <div></div>
                        </div>
                    </div>
   
                    <div class="num y_center" v-if="is_num">
                        <div class="num_title">{{$t('nftDetails.add_approve_num')}}</div>
                        <input type="number" v-model="approve_count">
                    </div>
                    <div class="y_center" >
                        <div class="flex_center hand but" v-if="Number(approve_num)<Number(info.rate)" @click="approve">{{$t('nftDetails.approve')}}</div>
                    </div>
                    <div class="y_center" >
                        <div class="flex_center hand" :class="Number(approve_num)>=Number(info.rate)?'but':'butn'" @click="buy">{{$t('nftDetails.submit')}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import walletHelper from "@/utils/walletHelper.js"
import { Loading } from 'element-ui';
import { MessageBox } from 'element-ui';
export default {
    name: "index",
    data() {
        return{
            token:0x0,
            ntf_switch:1,
            ids:-1,
            is_mtg:false,
            tx:true,
            sell_num:0,
            approve_num:0,
            balance:0,
            approve_count:1,
            info:{},
            loading:[],
            is_num:false,
            start_time:'1655350740000',
            doing:false,
        };
    },
    computed: {
		info_nft() {
			return this.$store.state.user.info_nft
		},
	},
    components:{},
    async mounted() {
        await this.$onLaunched;
        this.token = this.$route.params.id || 0
        this.init();
    },
    methods:{
        openBuy(){
            if(this.doing)return
            this.doing = true
            setTimeout(() => {
                this.doing = false
            }, 3000);
            this.checkTime(()=>{
                this.is_mtg=true
                walletHelper.getUsdt().methods.balanceOf(walletHelper.getAddress()).call().then(res=>{
                    this.balance=walletHelper.fromWei(res)
                    console.log(res)
                })
            })
        },
        checkTime(fun){
            if(this.ids>=0){
                this.get('/api/Community/quota', {sumi:this.ids}, 'POST').then(e => {
                //    if(){
                       fun()
                //    }else{
                    //    this.$toast({
                    //        title:e.msg,
                    //        type:'error'
                    //    })
                //    }
                })
            }
        },
        time(e){
            // let lefttime = parseInt((this.start_time - nowtime) / 1000)
            // let days = parseInt(e / ( 60 * 60 * 24))
            let h = parseInt(e / (60 * 60)) % 24
            let m = parseInt(e / (60)) % 60
            let s = parseInt(e % 60)
            h>9?h=h:h='0'+h
            s>9?s=s:s='0'+s
            m>9?m=m:m='0'+m
            var time = h+":"+m+":"+s
            this.$toast({
                title:'距离开始时间还剩'+time,
                type:'error',
            })
            return
        },
        init() {
            const that=this
            if(this.info_nft.symbol!=undefined){
                this.info=this.info_nft
            }
            setTimeout(()=>{
                walletHelper.getNFT().methods.symbol().call().then(( result)=>{
                    that.$set(that.info,'symbol',result)
                })
                walletHelper.getUsdt().methods.allowance(walletHelper.getAddress(),walletHelper.getBuyContract()).call().then(res=>{
                    that.approve_num=walletHelper.fromWei(res)
                })
                this.buysuss()
            },300)
            
        },
        buysuss(){
            const that=this
            // walletHelper.getNFT().methods.totalSupply().call().then((result)=>{
            //     that.$set(that.info,'totalSupply',result)
            // })
            // walletHelper.getNFT().methods.balanceOf(walletHelper.getAddress()).call().then((result)=>{
            //     that.$set(that.info,'balance',result)
            // })
            that.$set(that.info,'balance',500)

            walletHelper.getBuy().methods.rate().call().then((result)=>{
                console.log('单价',result)
                that.$set(that.info,'rate',walletHelper.fromWei(result))
            })
            
            walletHelper.getNFT().methods.getTokenIds().call().then(res=>{
                console.log('销售数',res)

                that.ids=Number(res)
            })
            console.log(this.token.toLowerCase())
            if(this.token.toLowerCase()==walletHelper.getAddress()){
                walletHelper.getBuy().methods.getCommunityCount(this.token).call().then(res=>{
                    that.sell_num=res
                })
            }
            walletHelper.getUsdt().methods.balanceOf(walletHelper.getAddress()).call().then(res=>{
                that.balance=walletHelper.fromWei(res)
                console.log(res)
            })
        },
        dd(){
            this.loading =Loading.service({
                lock: true,
                text: '交易正在处理中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },

        buy(){
            if(Number(this.approve_num)>=Number(this.info.rate)){
                const that=this
                let timeout=setTimeout(()=>{
                    that.buysuss()
                    that.loading.close();
                    // that.is_mtg=false
                    this.$toast({
                        type: 'error',
                        title: '交易超时'
                    });
                },600000)
                walletHelper.getNFT().methods.getTokenIds().call().then(re=>{
                    that.ids=Number(re)
                    that.checkTime(()=>{
                     
                            this.sendWeb3(walletHelper.getBuy().methods.buyTokens(that.token)).then(res=>{
                                console.log(res)
                                clearTimeout(timeout)
                                that.buysuss()
                                that.loading.close();
                                that.is_mtg=false
                            })
                    })
                })
            }else{
                this.$toast({
                        type: 'error',
                        title: '请先授权或返回重试'+this.approve_num+':'+this.info.rate
                    })
            }
        },
        approveFun(num){
            const that=this
            console.log(num)
            num=walletHelper.toWei(String(num))
            this.sendWeb3(walletHelper.getUsdt().methods.approve(walletHelper.getBuyContract(),String(num))).then(res1=>{
                    console.log(res1)
                    walletHelper.getUsdt().methods.allowance(walletHelper.getAddress(),walletHelper.getBuyContract()).call().then(res=>{
                        that.loading.close();
                        that.approve_num=walletHelper.fromWei(res)
                    })
                    walletHelper.getUsdt().methods.balanceOf(walletHelper.getAddress()).call().then(res=>{
                        that.loading.close();
                        that.balance=walletHelper.fromWei(res)
                    })
                })
        },
        approve(){
            if(this.info.rate==undefined||this.info.rate==0){
                this.$toast({
                    type: 'error',
                    title: '数据载入中请稍候'
                });
                return
            }
            if(!this.is_num){
                this.approve_count=1
            }
            if(this.approve_num<this.approve_count*this.info.rate){
                // let num=this.approve_count*this.info.rate
                // if(num>this.balance){
                //     MessageBox({
                //         type: 'error',
                //         message: 'USDT不足'
                //     });
                // }else{
                //     this.approveFun(Number(this.approve_count)*this.info.rate)
                // }
                this.approveFun(Number(1000)*this.info.rate)
            }else{
                this.$toast({
                    type: 'error',
                    title: '已授权,请提交或返回重试'
                });
            }
            
        },
        mtg(){
            this.tx = false
            setTimeout(() => {
				this.tx = true
                this.is_mtg = false
			}, 300);
        },
    },
};
</script>
<style lang="scss" scoped>
    .img{
        width: 100%;
    }
    .title{
        font-size: 22px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #00FFF6;
        padding: 10px;
        border-bottom: 10px solid #001020;
    }
    .box{
        padding: 15px; 
        background-color: #FFFFFF; 
        font-size: 24px; 
        font-weight: bold;
        margin-bottom: 10px;
    }
    .box_a{
        padding: 10px;
        font-size: var(--text);
        color: #ffffff;
    }
    .ntf_box{
        background-color: #FFFFFF;
        margin-top: 10px;
    }
    .ntf_row{
        padding: 10px 0;
        color: #929292;
    }
    .ntf_sw{
        border-bottom: 2px solid #000000;
        color: #000000;
        padding: 10px 0;
    }
    .bot_box{
        background-color: #08101F;
        position: fixed; 
        bottom: 0;
        height: 50px;
    }
    .purchase{
        width: 40%;
        background-color: #00F6FF;
        color: #000000;
        font-size: 18px;
        height: 50px;
    }
    .bot_center{
        height: 50px;
        width: 0;
        flex: 1;
        font-size: calc(var(--text) - 3px);
        padding-left: 20px;
        color: #ffffff;
        span{
            font-size: calc(var(--text) + 3px);
            color: #00F6FF;
        }
    }
    .back{
        position: absolute;
        bottom: 70px;
        width: 50px;
        height: 50px;
        background: rgba(255,255,255,0.8);
        right: 20px;
        border-radius: 50%;
        i{
            font-size: 30px;
            color: #000000;
        }
    }
    .mask_purchase{
        position: fixed;
        top: 0;
		left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
		z-index: 2;
    }
    .open{
        animation: rotate 0.1s linear; 
    }
    .close{
        animation: shut 0.1s linear forwards; 
    }
    .purchase_box{
        position: relative;
        z-index: 100;
        background-color: #FFFFFF;
        border-radius: 10px;
        padding: 15px;
        .add{
            margin-left: 10px;
            color: #EA8E2B;
            font-size: 20px;
            
        }
        .num{
            padding: 10px 0;
            input{
                width: 80%;
                height: 40px;
                border: 1px solid #666666;
                margin-top: 10px;
                border-radius: 10px;
                padding: 10px;
            }
        }
    }
    @keyframes rotate {
		0%{
            transform: scale(0.8);
			opacity: 0;
		}
		100%{
            transform: scale(1);
			opacity: 1;
		}       
	}
    @keyframes shut {
		0%{
            transform: scale(1);
			opacity: 1;
		}
		100%{
            transform: scale(0.8);
			opacity: 0;
		}       
	}
    .but{
        width: 80%;
        height: 45px;
        background-color: #EA8E2B;
        color: #FFFFFF;
        border-radius: 10px;
        margin-bottom: 10px;
    }
    .butn{
        width: 80%;
        height: 45px;
        background-color: #93918f;
        color: #FFFFFF;
        border-radius: 10px;
        margin-bottom: 10px;
    }
</style>